(function () {
    var breakPointNameExtraSmall = 'phone';
    var breakPointNameSmall = 'tablet';
    var breakPointNameMiddle = 'laptop';
    var breakPointNameLarge = 'desktop';

    var jRes = jRespond([
        {
            label: breakPointNameExtraSmall,
            enter: 0,
            exit: 767
        }, {
            label: breakPointNameSmall,
            enter: 768,
            exit: 991
        }, {
            label: breakPointNameMiddle,
            enter: 991,
            exit: 1199
        }, {
            label: breakPointNameLarge,
            enter: 1200,
            exit: 10000
        }
    ]);

    jRes.addFunc({
        breakpoint: breakPointNameExtraSmall,
        enter: function () {
            handlePointEnter(breakPointNameExtraSmall)
        },
        exit: function () {
            handlePointExit(breakPointNameExtraSmall)
        }
    });

    jRes.addFunc({
        breakpoint: breakPointNameSmall,
        enter: function () {
            handlePointEnter(breakPointNameSmall)
        },
        exit: function () {
            handlePointExit(breakPointNameSmall)
        }
    });

    jRes.addFunc({
        breakpoint: breakPointNameMiddle,
        enter: function () {
            handlePointEnter(breakPointNameMiddle)
        },
        exit: function () {
            handlePointExit(breakPointNameMiddle)
        }
    });

    jRes.addFunc({
        breakpoint: breakPointNameLarge,
        enter: function () {
            handlePointEnter(breakPointNameLarge)
        }, exit: function () {
            handlePointExit(breakPointNameLarge)
        }
    });

    function handlePointEnter(className) {
        document.querySelector('body').classList.add(className);
    }

    function handlePointExit(className) {
        document.querySelector('body').classList.remove(className);
    }
})();